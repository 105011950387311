import React from 'react';
import Layout from '../templates/layout';
import BasePageContent from '../components/utils/BasePageContent';
import { Box } from '@mui/material';
import Footer from '../components/Footer';
import Seo from '../components/utils/Seo';

const policyUrl = '/[Fiberpay]_Polityka_prywatności_Zapłatomat_20230919.pdf';

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Layout>
          <BasePageContent title='Polityka prywatności' centered='true'>
            <Box>
              <a download href={policyUrl} className='link-inline'>
                Pobierz (PDF)
              </a>
            </Box>
          </BasePageContent>
        </Layout>
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
};

export const Head = ({ location }) => (
  <Seo
    location={location}
    title='Polityka prywatności'
    description='Korzystając z naszych usług, powierzasz nam swoje dane. Zapewniamy ich bezpieczeństwo oraz umożliwiamy Ci kontrolę nad nimi'
  />
);

export default PrivacyPolicy;
